.landingPage {
  background-color: black;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  width: 100%;
  height: 100%;
}

.header {
  padding: 20px;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.logo {
  font-size: 2em;
  margin-bottom: 20px;
}

.whatsappButton {
  background-color: #25D366;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.whatsappButton a {
  color: white;
  text-decoration: none;
}

/* Style other sections similarly */
